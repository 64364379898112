import * as Analytics from '@iheartradio/web.analytics';
import { debounce, uuid } from '@iheartradio/web.utilities';
import {
  type CreateEmitter,
  createEmitter,
} from '@iheartradio/web.utilities/create-emitter';
import {
  type CreateStorage,
  createMemoryStorage,
} from '@iheartradio/web.utilities/create-storage';
import { isNonNullish } from 'remeda';

import * as Playback from './player:types.js';
import {
  getAnalyticsData,
  getAnalyticsStationData,
} from './utilities:get-analytics-data.js';

export function createAnalyticsSubscription<Station extends Playback.Station>({
  state,
  timeState,
  analytics,
}: {
  state: CreateStorage.Storage<Playback.PlayerState<Station>>;
  timeState: CreateStorage.Storage<{ time: Playback.Time }>;
  analytics: Analytics.Analytics.AnalyticsMethods;
}): CreateEmitter.Emitter<
  CreateEmitter.Subscription<Playback.Player<Station>>
> {
  const analyticsState = createMemoryStorage<{
    isScanStation: boolean;
    isStreamStart: boolean;
    isTrackStart: boolean;
    streamSessionId: string;
    streamInitTime: number;
    playbackStartTime: number;
    scanStopType: Analytics.ScanStopType;
    startPosition: number;
    trackSessionId: string;
    oldTrackSessionId: string;
    oldVolume: number;
  }>({
    isScanStation: false,
    isStreamStart: false,
    isTrackStart: false,
    streamSessionId: '',
    streamInitTime: 0,
    playbackStartTime: 0,
    scanStopType: 'auto_end',
    startPosition: 0,
    trackSessionId: '',
    oldTrackSessionId: '',
    oldVolume: 0,
  });

  const preroll = () => {
    const user = analytics.getGlobalData().user;
    const { station, metadata, queue, index } = state.deserialize();

    analyticsState.set('streamInitTime', Date.now());

    if (station && metadata) {
      const data = getAnalyticsData({
        analyticsState: {
          playbackStartTime: analyticsState.get('playbackStartTime'),
          startPosition: analyticsState.get('startPosition'),
          streamInitTime: analyticsState.get('streamInitTime'),
          streamSessionId: analyticsState.get('streamSessionId'),
        },
        followed: metadata.data?.followed ?? false,
        index,
        metadata: metadata.data,
        queue,
        station,
        user,
      });

      const isSameTrack =
        analyticsState.get('oldTrackSessionId') ===
        analyticsState.get('trackSessionId');

      if (data) {
        if (
          (metadata.type === Playback.MetadataType.Track ||
            metadata.type === Playback.MetadataType.Episode) &&
          !isSameTrack
        ) {
          analyticsState.set(
            'oldTrackSessionId',
            analyticsState.get('trackSessionId'),
          );
          analytics.track({
            type: Analytics.eventType.enum.TrackStart,
            data: {
              station: {
                ...data.station,
                subSessionId: analyticsState.get('trackSessionId'),
              },
            },
          });
          analyticsState.set('isTrackStart', true);
        } else if (!analyticsState.get('isTrackStart')) {
          analytics.track({
            type: Analytics.eventType.enum.StreamStart,
            data,
          });
          analyticsState.set('isStreamStart', true);
        }
      }
    }
  };

  const analyticsReporting = createEmitter<
    CreateEmitter.Subscription<Playback.Player<Station>>
  >({
    initialize() {
      const { volume } = state.deserialize();
      analyticsState.set('streamSessionId', uuid());
      analyticsState.set('trackSessionId', uuid());
      analyticsState.set('oldVolume', volume);
    },

    // when user click to play for other station then we want to set up stream and track session Ids
    load() {
      analyticsState.set('streamSessionId', uuid());
      analyticsState.set('trackSessionId', uuid());
    },

    // whenever user play the station we want to set up playback start position, If it is same Track.
    play() {
      analytics.setGlobalData({ device: { isPlaying: true } });
      const { time, station } = state.deserialize();
      const isSameTrack =
        analyticsState.get('oldTrackSessionId') ===
        analyticsState.get('trackSessionId');
      analyticsState.set('playbackStartTime', Date.now());
      if (isSameTrack) {
        analyticsState.set('startPosition', Math.round(time.position));
      }
      if (station?.type === Playback.StationType.Scan) {
        analyticsState.set('isScanStation', true);
      } else {
        analyticsState.set('isScanStation', false);
      }
    },

    // when ever user switch to other station (except stream Live radio) or click pause button to current track then we want to capture track end or stream end events
    pause() {
      const { time, history, queue, index } = state.deserialize();
      const user = analytics.getGlobalData().user;
      analytics.setGlobalData({ device: { isPlaying: false } });
      const { station: oldstation, item } =
        history
          ?.filter(
            history => history.item.type !== Playback.QueueItemType.Event,
          )
          .at(-1) ?? {};
      const isLoadedNewTrack =
        analyticsState.get('oldTrackSessionId') &&
        analyticsState.get('oldTrackSessionId') !==
          analyticsState.get('trackSessionId');

      if (
        (analyticsState.get('isStreamStart') ||
          analyticsState.get('isTrackStart')) &&
        oldstation &&
        item?.meta
      ) {
        const data = getAnalyticsData({
          analyticsState: {
            playbackStartTime: analyticsState.get('playbackStartTime'),
            startPosition: analyticsState.get('startPosition'),
            streamInitTime: analyticsState.get('streamInitTime'),
            streamSessionId: analyticsState.get('streamSessionId'),
          },
          followed: item?.meta?.followed ?? false,
          index,
          metadata: item?.meta,
          queue,
          station: oldstation,
          user,
        });

        if (data) {
          const listenTime =
            time.position - analyticsState.get('startPosition');
          if (analyticsState.get('isTrackStart')) {
            analytics.track({
              type: Analytics.eventType.enum.TrackEnd,
              data: {
                station: {
                  ...data.station,
                  subSessionId: analyticsState.get('trackSessionId'),
                  listenTime: listenTime > 0 ? listenTime : time.position,
                  endReason: 'pause',
                },
              },
            });
            analyticsState.set('isTrackStart', false);
          } else {
            analytics.track({
              type: Analytics.eventType.enum.StreamEnd,
              data: {
                station: {
                  ...data.station,
                  listenTime: listenTime > 0 ? listenTime : time.position,
                },
              },
            });
            analyticsState.set('isStreamStart', false);
          }
          if (isLoadedNewTrack) {
            analyticsState.set('startPosition', 0);
          }
        }
      }
    },

    // when ever user switch to other stream type station (eg Live radio) or click pause button to stream type station then we want to capture stream end events
    stop() {
      const { time, history, queue, index } = state.deserialize();
      const user = analytics.getGlobalData().user;
      analytics.setGlobalData({ device: { isPlaying: false } });
      const isLoadedNewTrack =
        analyticsState.get('oldTrackSessionId') &&
        analyticsState.get('oldTrackSessionId') !==
          analyticsState.get('trackSessionId');

      const { station: oldstation, item } =
        history
          ?.filter(
            history => history.item.type !== Playback.QueueItemType.Event,
          )
          .at(-1) ?? {};
      if (
        (analyticsState.get('isStreamStart') ||
          analyticsState.get('isTrackStart')) &&
        oldstation &&
        item?.meta
      ) {
        const data = getAnalyticsData({
          analyticsState: {
            playbackStartTime: analyticsState.get('playbackStartTime'),
            startPosition: analyticsState.get('startPosition'),
            streamInitTime: analyticsState.get('streamInitTime'),
            streamSessionId: analyticsState.get('streamSessionId'),
          },
          followed: item?.meta?.followed ?? false,
          index,
          metadata: item?.meta,
          queue,
          station: oldstation,
          user,
        });

        if (data) {
          const listenTime =
            time.position - analyticsState.get('startPosition');
          // Note - As per my testing I don't see stop fire on ending any track but added this condition If that condition is occuring
          if (analyticsState.get('isTrackStart')) {
            analytics.track({
              type: Analytics.eventType.enum.TrackEnd,
              data: {
                station: {
                  ...data.station,
                  subSessionId: analyticsState.get('trackSessionId'),
                  listenTime: listenTime > 0 ? listenTime : time.position,
                  endReason: 'pause',
                },
              },
            });
            analyticsState.set('isTrackStart', false);
          } else {
            analytics.track({
              type: Analytics.eventType.enum.StreamEnd,
              data: {
                station: {
                  ...data.station,
                  listenTime: listenTime > 0 ? listenTime : time.position,
                },
              },
            });
            if (analyticsState.get('isScanStation')) {
              analytics.track({
                type: Analytics.eventType.enum.ScanStopped,
                data: {
                  view: {
                    pageName: 'home', // this will eventually NOT be hardcoded ... so we'll have to figure THAT out [DEM 2025/01/12]
                  },
                  scan: {
                    stopType: 'miniplayer'
                  }
                }
              });
            }
            analyticsState.set('isStreamStart', false);
          }
          if (isLoadedNewTrack) {
            analyticsState.set('startPosition', 0);
          }
        }
      }
    },

    // when user switch to any other station then always preroll hevent happens that's why we are capturing stream start and track start event in this method
    preroll: debounce(preroll, 500),

    fastForward() {
      const { metadata, pageName } = state.deserialize();
      if (metadata?.data?.podcastId && pageName) {
        const { time } = timeState.deserialize();

        const assets = {
          id: `${Playback.StationType.Podcast}|${metadata?.data?.podcastId}`,
          name: metadata?.data?.subtitle ?? '',
          subid: `${metadata?.type}|${metadata?.data?.id}`,
          subname: metadata?.data?.description ?? '',
        };

        analytics.track({
          type: Analytics.eventType.enum.Forward30,
          data: {
            pageName: pageName ?? '',
            station: {
              asset: assets,
              playheadPosition: time.position?.toFixed(2),
            },
            item: {
              asset: assets,
            },
          },
        });
      }
    },

    rewind() {
      const { metadata, pageName } = state.deserialize();

      if (metadata?.data?.podcastId && pageName) {
        const { time } = timeState.deserialize();
        const assets = {
          id: `${Playback.StationType.Podcast}|${metadata?.data?.podcastId}`,
          name: metadata?.data?.subtitle ?? '',
          subid: `${metadata?.type}|${metadata?.data?.id}`,
          subname: metadata?.data?.description ?? '',
        };
        analytics.track({
          type: Analytics.eventType.enum.Back15,
          data: {
            pageName: pageName ?? '',
            station: {
              asset: assets,
              playheadPosition: time.position?.toFixed(2),
            },
            item: {
              asset: assets,
            },
          },
        });
      }
    },

    // when user click to next track then we want to fire the track end event for previous track
    next() {
      const user = analytics.getGlobalData().user;
      const { queue, index, history, station, time } = state.deserialize();

      if (station?.type !== Playback.StationType.Scan) {
        const listenTime = time.position - analyticsState.get('startPosition');

        const startPosition = queue[index]?.starttime ?? 0;
        const { station: oldstation, item } =
          history
            ?.filter(
              history => history.item.type !== Playback.QueueItemType.Event,
            )
            .at(-1) ?? {};
        analyticsState.set('startPosition', Math.round(startPosition));

        analyticsState.set('trackSessionId', uuid());

        if (oldstation && item?.meta) {
          const data = getAnalyticsData({
            analyticsState: {
              playbackStartTime: analyticsState.get('playbackStartTime'),
              startPosition: analyticsState.get('startPosition'),
              streamInitTime: analyticsState.get('streamInitTime'),
              streamSessionId: analyticsState.get('streamSessionId'),
            },
            followed: item?.meta?.followed ?? false,
            index,
            metadata: item?.meta,
            queue,
            station: oldstation,
            user,
          });

          if (data) {
            analytics.track({
              type: Analytics.eventType.enum.TrackEnd,
              data: {
                station: {
                  ...data.station,
                  subSessionId: analyticsState.get('trackSessionId'),
                  endReason: 'new_station_start',
                  listenTime: listenTime > 0 ? listenTime : time.position,
                },
              },
            });

            analyticsState.set('oldTrackSessionId', '');
            analyticsState.set('isTrackStart', false);
          }
        }
      }
    },

    setScanning(_, { isScanning, skip }) {
      const { history, station } = state.deserialize();
      const skipScanning = isNonNullish(skip) && skip === true;

      if (!isScanning && !skipScanning) {
        const { station: oldstation } =
          history
            ?.filter(
              history => history.item.type !== Playback.QueueItemType.Event,
            )
            .at(-1) ?? {};
        if (station?.type === Playback.StationType.Live && oldstation?.type === Playback.StationType.Scan) {
          analytics.track({
            type: Analytics.eventType.enum.ScanStopped,
            data: {
              view: {
                pageName: 'home'
              },
              scan: {
                stopType: 'auto_end'
              }
            }
          })
        }
      }
    },

    setVolume(value) {
      const { history, index, queue } = state.deserialize();
      const { station, item } =
        history
          ?.filter(
            history => history.item.type !== Playback.QueueItemType.Event,
          )
          .at(-1) ?? {};
      const { volume } = state.deserialize();
      const user = analytics.getGlobalData().user;
      if (station && item?.meta) {
        const stationData = getAnalyticsStationData({
          index,
          metadata: item?.meta,
          station,
          queue,
          user,
        });
        analytics.track({
          type: Analytics.eventType.enum.VolumeChange,
          data: {
            station: {
              asset: {
                id: stationData.stationId,
                name: stationData.stationName,
                ...(stationData.stationSubid && {
                  subid: stationData.stationSubid?.toString(),
                  subname: stationData.stationSubname,
                }),
              },
            },
            device: {
              oldVolume: Math.round(analyticsState.get('oldVolume')),
              newVolume: Math.round(volume),
            },
          },
        });
      }
      analyticsState.set('oldVolume', value);
    },
  });

  return analyticsReporting;
}
