import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { HttpMethods } from '../../../httpUtils/constants.js';
import type { V3 } from '../../../types/poweramp.js';
import { implement } from '../../implement.js';
import { numberIdSchema } from '../../schemas/common.js';

const c = initContract();

export type GetStationMetaResponseBody = V3.GetStation.ResponseBody & {
  ads: {
    audio_ad_provider: 'ad-providers/triton' | 'ad-providers/adswizz' | null;
    enable_triton_token: boolean;
    provider_id: number;
  };
  adswizz: {
    publisher_id: string;
    enableAdswizzTargeting: boolean;
    adswizzHost: string;
  };
  adswizz_zones?: {
    'audio-fill-zone'?: string;
    'audio-exchange-zone'?: string;
    'audio-zone'?: string;
    'optimized-audio-fill-zone'?: string;
    'display-zone'?: string;
  };
  band: string;
  callLetterAlias: string | null;
  callLetterRoyalty: string | null;
  created: number;
  esid: string | null;
  email: string | null;
  fcc_facility_id: number | null;
  feeds: {
    enableTritonTracking: boolean;
    feed: string;
    childOriented: boolean | null;
  };
  format: string;
  freq_clean: string;
  gracenote_enabled: boolean;
  isActive: boolean;
  name_clean: string;
  phone?: string;
  raw_id: string;
  rds: string;
  rds_pi_code: string;
  recentlyPlayedEnabled: boolean;
  siri_logo: string | null;
  sms?: string;
  social: {
    facebook?: string | null;
    twitter?: string | null;
    instagram?: string | null;
    google?: string | null;
    snapchat?: string | null;
    tiktok?: string | null;
    youtube?: string | null;
    threads?: string | null;
  };
  streamingPlatform: string;
  streams: {
    hls_stream: string | null;
    secure_hls_stream: string | null;
    pls_stream: string | null;
    secure_pls_stream: string | null;
    abacast_stream: string | null;
    shoutcast_stream: string | null;
    secure_shoutcast_stream: string | null;
    pivot_hls_stream: string | null;
    dash_stream: string | null;
    secure_dash_stream: string | null;
    secure_mp3_pls_stream: string | null;
  };
  website: string;
};

export type StreamTrackHistory =
  V3.GetStreamTrackHistory.ResponseBody['data'][number];

export const livemetaContract = c.router(
  {
    getCurrentTrackMeta: {
      method: HttpMethods.Get,
      path: '/stream/:streamId/currentTrackMeta',
      pathParams: implement<V3.GetCurrentTrack.RequestParams>().from({
        streamId: numberIdSchema,
      }),
      query: implement<V3.GetCurrentTrack.RequestQuery>().from({
        /**
         * return in-stream metadata (if available) for a station even if third party listening is disabled.
         * @default false
         */
        defaultMetadata: z.boolean().optional(),
      }),
      responses: {
        200: c.type<V3.GetCurrentTrack.ResponseBody>(),
        204: c.type<never>(),
      },
    },

    getStationMeta: {
      method: HttpMethods.Get,
      path: '/stream/:stationId/station-meta',
      pathParams: implement<V3.GetStation.RequestParams>().from({
        stationId: numberIdSchema,
      }),
      responses: {
        200: implement<GetStationMetaResponseBody>().from({
          ads: z.object({
            audio_ad_provider: z
              .union([
                z.literal('ad-providers/triton'),
                z.literal('ad-providers/adswizz'),
              ])
              .nullable(),
            enable_triton_token: z.boolean(),
            provider_id: z.number(),
          }),
          adswizz: z.object({
            publisher_id: z.string(),
            enableAdswizzTargeting: z.boolean(),
            adswizzHost: z.string(),
          }),
          adswizz_zones: z
            .object({
              'audio-fill-zone': z.string().optional(),
              'audio-exchange-zone': z.string().optional(),
              'audio-zone': z.string().optional(),
              'optimized-audio-fill-zone': z.string().optional(),
              'display-zone': z.string().optional(),
            })
            .optional(),
          ageLimit: z.number().optional(),
          band: z.string(),
          brand: z.string().optional(),
          callLetterAlias: z.string().nullable(),
          callLetterRoyalty: z.string().nullable(),
          callLetters: z.string(),
          childOriented: z.boolean().optional(),
          country: z.string().optional(),
          created: z.number(),
          createdAt: z.number().optional(),
          cume: z.number().optional(),
          description: z.string().optional(),
          email: z.string().nullable(),
          esId: z.string().optional(),
          esid: z.string().nullable(),
          fccFacilityId: z.number().optional(),
          fcc_facility_id: z.number().nullable(),
          feeds: z.object({
            enableTritonTracking: z.boolean(),
            feed: z.string(),
            childOriented: z.boolean().nullable(),
          }),
          format: z.string(),
          freq_clean: z.string(),
          freq: z.string().optional(),
          genres: z.array(
            z.object({
              country: z.string().optional(),
              id: z.number(),
              isPrimary: z.boolean(),
              name: z.string().optional(),
              sortIndex: z.number(),
            }),
          ),
          gracenote_enabled: z.boolean(),
          gracenoteEnabled: z.boolean().optional(),
          id: z.number(),
          isActive: z.boolean(),
          jsonSource: z.any().optional(),
          link: z.string().optional(),
          logo: z.string().optional(),
          markets: z
            .array(
              z.object({
                city: z.string(),
                cityId: z.number(),
                country: z.string(),
                countryId: z.number(),
                id: z.number(),
                isOrigin: z.boolean(),
                isPrimary: z.boolean(),
                name: z.string(),
                sortIndex: z.number().optional(),
                state: z.string(),
                stateId: z.number(),
              }),
            )
            .optional(),
          modified: z.number().optional(),
          name: z.string(),
          name_clean: z.string(),
          phone: z.string().optional(),
          provider: z.string().optional(),
          raw_id: z.string(),
          rds: z.string(),
          rds_pi_code: z.string(),
          rdsPiCode: z.string().optional(),
          recentlyPlayedEnabled: z.boolean(),
          search: z
            .object({
              clickBoost: z.number().optional(),
              normCallLetters: z.array(z.string()),
              normCity: z.string().optional(),
              normCume: z.number().optional(),
              normDescription: z.array(z.string()).optional(),
              normDescriptionCollapsed: z.string().optional(),
              normFreq: z.array(z.string()).optional(),
              normGenre: z.array(z.string()).optional(),
              normLiveName: z.array(z.string()),
              primaryMarketId: z.number().optional(),
              secondaryMarketIds: z.array(z.number()).optional(),
              state: z.string().optional(),
              zips: z.string().optional(),
            })
            .optional(),
          siri_logo: z.string().nullable(),
          sms: z.string().optional(),
          social: z.object({
            facebook: z.string().optional().nullable(),
            twitter: z.string().optional().nullable(),
            instagram: z.string().optional().nullable(),
            google: z.string().optional().nullable(),
            snapchat: z.string().optional().nullable(),
            tiktok: z.string().optional().nullable(),
            youtube: z.string().optional().nullable(),
            threads: z.string().optional().nullable(),
          }),
          streamingPlatform: z.string(),
          streams: z.object({
            hls_stream: z.string().nullable(),
            secure_hls_stream: z.string().nullable(),
            pls_stream: z.string().nullable(),
            secure_pls_stream: z.string().nullable(),
            abacast_stream: z.string().nullable(),
            shoutcast_stream: z.string().nullable(),
            secure_shoutcast_stream: z.string().nullable(),
            pivot_hls_stream: z.string().nullable(),
            dash_stream: z.string().nullable(),
            secure_dash_stream: z.string().nullable(),
            secure_mp3_pls_stream: z.string().nullable(),
          }),
          talkbackEnabled: z.boolean().optional(),
          website: z.string(),
        }),
      },
    },

    getStreamTrackHistory: {
      method: HttpMethods.Get,
      path: '/stream/:streamId/trackHistory',
      pathParams: implement<V3.GetStreamTrackHistory.RequestParams>().from({
        streamId: numberIdSchema,
      }),
      query: c.type<V3.GetStreamTrackHistory.RequestQuery>(),
      responses: {
        200: c.type<V3.GetStreamTrackHistory.ResponseBody>(),
      },
    },

    getStreamTopArtists: {
      method: HttpMethods.Get,
      path: '/stream/:streamId/topArtists',
      pathParams: implement<V3.GetStreamTopArtists.RequestParams>().from({
        streamId: numberIdSchema,
      }),
      query: c.type<V3.GetStreamTopArtists.RequestQuery>(),
      responses: {
        200: c.type<V3.GetStreamTopArtists.ResponseBody>(),
      },
    },
  },
  {
    pathPrefix: '/live-meta',
  },
);
