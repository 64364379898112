import { isUndefined } from '@iheartradio/web.utilities';
import { createEmitter } from '@iheartradio/web.utilities/create-emitter';

import * as Schemas from './player:schemas.js';
import * as Playback from './player:types.js';
import {
  type ExtendedErrorFactoryOptions,
  ExtendedError,
} from './utility:extended-error.js';

export enum PlayerErrorCode {
  AdBlocker = 'AdBlocker',
  AdsMetadata = 'AdsMetadata',
  AutoplayBlocked = 'AutoplayBlocked',
  ApiError = 'ApiError',
  ApiNoMoreSongs = 'ApiNoMoreSongs',
  CriticalError = 'CriticalError',
  Generic = 'Generic',
  InternalPlayerError = 'InternalPlayerError',
  InvalidMetadata = 'InvalidMetadata',
  InvalidPageKey = 'InvalidPageKey',
  InvalidSeekType = 'InvalidSeekType',
  InvalidSeekValue = 'InvalidSeekValue',
  InvalidSpeed = 'InvalidSpeed',
  InvalidSpeedType = 'InvalidSpeedType',
  InvalidStation = 'InvalidStation',
  InvalidTime = 'InvalidTime',
  InvalidVolume = 'InvalidVolume',
  Midroll = 'Midroll',
  MissingStreams = 'MissingStreams',
  NetworkError = 'NetworkError',
  PlayAttemptFailed = 'PlayAttemptFailed',
  Preroll = 'Preroll',
  RestrictedDuringAdBreak = 'RestrictedDuringAdBreak',
  SkipLimit = 'SkipLimit',
  Targeting = 'Targeting',
  UnsupportedMethod = 'UnsupportedMethod',
}

export enum PlayerErrorMessages {
  AdBlocker = 'It looks like you have an ad blocker configured, please disable it before streaming',
  AdsMetadata = 'Could not set player metadata for playback ad',
  ApiError = 'There was an error fetching the streams',
  ApiNoMoreSongs = 'Sorry, this station has run out of songs to play.',
  AutoplayBlocked = 'Attempted to autoplay, but something went wrong.',
  CriticalError = 'There was a critical error loading the player, please try refreshing the browser',
  Generic = 'Uh oh! Something went wrong :(',
  InternalPlayerError = 'Uh oh! An internal player error occurred.',
  InvalidMetadata = 'Metadata follows the incorrect shape',
  InvalidPageKey = 'Missing or invalid page key.',
  InvalidSeekType = 'Seeking during a stream is not possible.',
  InvalidSeekValue = 'Must take a positive integer value.',
  InvalidSpeed = '"speed" must be a number between 0.25 and 4.',
  InvalidSpeedType = 'Player speed can only be updated for episodes.',
  InvalidStation = '"station" is "null". You must load a station into playback first before triggering this method.',
  InvalidTime = 'Position and duration must be a number greater than 0.',
  InvalidVolume = 'Volume must be a number between 0 and 100.',
  Midroll = 'Attempted to play a midroll ad, but something went wrong.',
  MissingStreams = 'There were no streams returned for this station.',
  NetworkError = 'Uh oh! A network error occurred :(',
  PlayAttemptFailed = 'Attempted to play, but something went wrong.',
  Preroll = 'Attempted to play a preroll ad, but something went wrong.',
  ResolvedStreams = 'Could not resolve any streams for this station.',
  RestrictedDuringAdBreak = 'This method is restricted for ad breaks.',
  SkipLimit = 'The user has reached their skip limit.',
  Targeting = 'Attempted to set targeting parameters for playback ad, but something went wrong',
  UnsupportedMethod = 'This method is not supported for this station type.',
  ValidStreams = 'There were no valid streams available for this station.',
}

export const PlayerError = createEmitter({
  Code: PlayerErrorCode,

  new(options: ExtendedErrorFactoryOptions<PlayerErrorCode>) {
    const extendedError = new ExtendedError<PlayerErrorCode>({
      ...options,
      message: options.message ?? PlayerError[options.code]?.message,
      name: 'Playback.Error',
    });
    Object.defineProperty(extendedError, 'message', { enumerable: true });

    return extendedError;
  },

  [PlayerErrorCode.AdBlocker]: {
    code: PlayerErrorCode.AdBlocker,
    message: PlayerErrorMessages.AdBlocker,
  },

  [PlayerErrorCode.AdsMetadata]: {
    code: PlayerErrorCode.AdsMetadata,
    message: PlayerErrorMessages.AdsMetadata,
  },

  [PlayerErrorCode.ApiError]: {
    code: PlayerErrorCode.ApiError,
    message: PlayerErrorMessages.ApiError,
  },

  [PlayerErrorCode.ApiNoMoreSongs]: {
    code: PlayerErrorCode.ApiNoMoreSongs,
    message: PlayerErrorMessages.ApiNoMoreSongs,
  },

  [PlayerErrorCode.AutoplayBlocked]: {
    code: PlayerErrorCode.AutoplayBlocked,
    message: PlayerErrorMessages.AutoplayBlocked,
  },

  [PlayerErrorCode.CriticalError]: {
    code: PlayerErrorCode.CriticalError,
    message: PlayerErrorMessages.CriticalError,
  },

  [PlayerErrorCode.Generic]: {
    code: PlayerErrorCode.Generic,
    message: PlayerErrorMessages.Generic,
  },

  [PlayerErrorCode.InternalPlayerError]: {
    code: PlayerErrorCode.InternalPlayerError,
    message: PlayerErrorMessages.InternalPlayerError,
  },

  [PlayerErrorCode.InvalidMetadata]: {
    code: PlayerErrorCode.InvalidMetadata,
    message: PlayerErrorMessages.InvalidMetadata,

    validate(metadata: Playback.Metadata) {
      try {
        return Schemas.MetadataSchema.parse(metadata);
      } catch {
        throw PlayerError.new({
          code: PlayerErrorCode.InvalidMetadata,
          data: metadata,
          message: PlayerErrorMessages.InvalidMetadata,
        });
      }
    },
  },

  [PlayerErrorCode.InvalidPageKey]: {
    code: PlayerErrorCode.InvalidPageKey,
    message: PlayerErrorMessages.InvalidPageKey,
  },

  [PlayerErrorCode.InvalidSeekType]: {
    code: PlayerErrorCode.InvalidSeekType,
    message: PlayerErrorMessages.InvalidSeekType,

    validate(type: Playback.QueueItemType) {
      if (type === Playback.QueueItemType.Stream) {
        throw PlayerError.new({
          code: PlayerErrorCode.InvalidSeekType,
          data: { type },
          message: PlayerErrorMessages.InvalidSeekType,
        });
      }
    },
  },

  [PlayerErrorCode.InvalidSeekValue]: {
    code: PlayerErrorCode.InvalidSeekValue,
    message: PlayerErrorMessages.InvalidSeekValue,

    validate(seconds: Playback.SeekValue) {
      try {
        return Schemas.SeekValue.parse(seconds);
      } catch {
        throw PlayerError.new({
          code: PlayerErrorCode.InvalidSeekValue,
          data: { seconds },
          message: PlayerErrorMessages.InvalidSeekValue,
        });
      }
    },
  },

  [PlayerErrorCode.InvalidSpeed]: {
    code: PlayerErrorCode.InvalidSpeed,
    message: PlayerErrorMessages.InvalidSpeed,

    validate(speed: Playback.Speed) {
      try {
        return Schemas.SpeedSchema.parse(speed);
      } catch {
        throw PlayerError.new({
          code: PlayerErrorCode.InvalidSpeed,
          data: { speed },
          message: PlayerErrorMessages.InvalidSpeed,
        });
      }
    },
  },

  [PlayerErrorCode.InvalidSpeedType]: {
    code: PlayerErrorCode.InvalidSpeedType,
    message: PlayerErrorMessages.InvalidSpeedType,

    validate(type: Playback.QueueItemType) {
      if (type !== Playback.QueueItemType.Episode) {
        throw PlayerError.new({
          code: PlayerErrorCode.InvalidSpeedType,
          data: { type },
          message: PlayerErrorMessages.InvalidSpeedType,
        });
      }
    },
  },

  [PlayerErrorCode.InvalidStation]: {
    code: PlayerErrorCode.InvalidStation,
    message: PlayerErrorMessages.InvalidStation,

    validate<Station extends Playback.Station | null>(station?: Station) {
      try {
        return Schemas.StationSchema.unwrap().parse(station);
      } catch {
        throw PlayerError.new({
          code: PlayerErrorCode.InvalidStation,
          data: { station },
          message: PlayerErrorMessages.InvalidStation,
        });
      }
    },
  },

  [PlayerErrorCode.InvalidTime]: {
    code: PlayerErrorCode.InvalidTime,
    message: PlayerErrorMessages.InvalidTime,

    validate(time: Playback.Time) {
      try {
        return Schemas.TimeSchema.parse(time);
      } catch {
        throw PlayerError.new({
          code: PlayerErrorCode.InvalidTime,
          data: { time },
          message: PlayerErrorMessages.InvalidTime,
        });
      }
    },
  },

  [PlayerErrorCode.InvalidVolume]: {
    code: PlayerErrorCode.InvalidVolume,
    message: PlayerErrorMessages.InvalidVolume,

    validate(volume: Playback.Volume) {
      try {
        return Schemas.VolumeSchema.parse(volume);
      } catch {
        throw PlayerError.new({
          code: PlayerErrorCode.InvalidVolume,
          data: { volume },
          message: PlayerErrorMessages.InvalidVolume,
        });
      }
    },
  },

  [PlayerErrorCode.Midroll]: {
    code: PlayerErrorCode.Midroll,
    message: PlayerErrorMessages.Midroll,
  },

  [PlayerErrorCode.MissingStreams]: {
    code: PlayerErrorCode.MissingStreams,
    message: PlayerErrorMessages.MissingStreams,
  },

  [PlayerErrorCode.NetworkError]: {
    code: PlayerErrorCode.NetworkError,
    message: PlayerErrorMessages.NetworkError,
  },

  [PlayerErrorCode.PlayAttemptFailed]: {
    code: PlayerErrorCode.PlayAttemptFailed,
    message: PlayerErrorMessages.PlayAttemptFailed,
  },

  [PlayerErrorCode.Preroll]: {
    code: PlayerErrorCode.Preroll,
    message: PlayerErrorMessages.Preroll,
  },

  [PlayerErrorCode.RestrictedDuringAdBreak]: {
    code: PlayerErrorCode.RestrictedDuringAdBreak,
    message: PlayerErrorMessages.RestrictedDuringAdBreak,

    validate(adbreak: boolean) {
      if (adbreak) {
        throw PlayerError.new({
          code: PlayerErrorCode.RestrictedDuringAdBreak,
          message: PlayerErrorMessages.RestrictedDuringAdBreak,
        });
      }
    },
  },

  [PlayerErrorCode.SkipLimit]: {
    code: PlayerErrorCode.SkipLimit,
    message: PlayerErrorMessages.SkipLimit,

    validate(skips: number) {
      if (skips < 0) {
        throw PlayerError.new({
          code: PlayerErrorCode.SkipLimit,
          data: { skips },
          message: PlayerErrorMessages.SkipLimit,
        });
      }
    },
  },

  [PlayerErrorCode.Targeting]: {
    code: PlayerErrorCode.Targeting,
    message: PlayerErrorMessages.Targeting,
  },

  [PlayerErrorCode.UnsupportedMethod]: {
    code: PlayerErrorCode.UnsupportedMethod,
    message: PlayerErrorMessages.UnsupportedMethod,

    validate<Fn extends (...args: any[]) => any>(fn?: Fn) {
      if (isUndefined(fn)) {
        throw PlayerError.new({
          code: PlayerErrorCode.UnsupportedMethod,
          message: PlayerErrorMessages.UnsupportedMethod,
        });
      }

      return fn;
    },
  },
});
