import { isArray, isNull, isObject } from '@iheartradio/web.utilities';
import type { CreateStorage } from '@iheartradio/web.utilities/create-storage';
import { type Dispatch, type SetStateAction, useEffect } from 'react';

import type * as Playback from './player:types.js';
import type { createContext } from './react:create-context.js';

export function SubscribeToPlayerState<Station extends Playback.Station>({
  ads,
  state,
  time,
  AdsContext,
  ErrorContext,
  setMetadata,
  StateContext,
  TimeContext,
}: {
  ads: CreateStorage.Storage<Playback.Ads>;
  state: CreateStorage.Storage<Playback.PlayerState<Station>>;
  time: CreateStorage.Storage<{ time: Playback.Time }>;
  AdsContext: ReturnType<typeof createContext<Playback.Ads>>;
  ErrorContext: ReturnType<typeof createContext<Error[] | null>>;
  setMetadata: Dispatch<SetStateAction<Playback.Metadata>>;
  StateContext: ReturnType<
    typeof createContext<
      Omit<Playback.PlayerState<Station>, 'errors' | 'metadata' | 'time'>
    >
  >;
  TimeContext: ReturnType<typeof createContext<Playback.Time>>;
}) {
  const [, setAds] = AdsContext.useContext();
  const [, setState] = StateContext.useContext();
  const [, setTime] = TimeContext.useContext();
  const [error, setError] = ErrorContext.useContext();

  useEffect(() => {
    const unsubscribe = ads.subscribe({
      serialize(_, data) {
        setAds(current => ({ ...current, ...data }));
      },

      set(_, key, value) {
        setAds(current => ({ ...current, [key]: value }));
      },
    });

    return unsubscribe;
  }, [ads, setAds]);

  useEffect(() => {
    const unsubscribe = state.subscribe({
      serialize(_, { errors, metadata, ...state }) {
        if (isArray(errors)) {
          const [error] = errors.slice(-1);

          if (error instanceof Error) {
            setError([error]);
          }
        }

        if (isObject(metadata)) {
          setMetadata(metadata);
        }

        setState(current => ({ ...current, ...state }));
      },

      set(_, key, value) {
        if (key === 'errors' && Array.isArray(value)) {
          setError(value.slice(-1) as unknown as Error[]);
        } else if (key === 'metadata') {
          setMetadata(value as Playback.Metadata);
        } else if (key === 'time') {
          setTime(value as Playback.Time);
        } else {
          setState(current => ({ ...current, [key]: value }));
        }
      },
    });

    return unsubscribe;
  }, [setError, setMetadata, setState, setTime, state]);

  useEffect(() => {
    const unsubscribe = time.subscribe({
      serialize(_, { time }) {
        if (isObject(time)) {
          setTime(time);
        }
      },
      set(_, _key, value) {
        setTime(value);
      },
    });

    return unsubscribe;
  }, [time, setTime]);

  useEffect(() => {
    if (isNull(error)) {
      return;
    }

    setError(null);
  }, [error, setError]);

  return null;
}
