import {
  isNull,
  isUndefined,
  throttle,
  uuid,
} from '@iheartradio/web.utilities';
import { createEmitter } from '@iheartradio/web.utilities/create-emitter';
import type { Logger } from '@iheartradio/web.utilities/create-logger';
import {
  createMemoryStorage,
  createWebStorage,
} from '@iheartradio/web.utilities/create-storage';
import * as mime from 'mrmime';
import { clone, isDeepEqual, isNullish, omit } from 'remeda';

import {
  PlayerError,
  PlayerErrorCode,
  PlayerErrorMessages,
} from './player:error.js';
import { CompanionsSchema } from './player:schemas.js';
import * as Playback from './player:types.js';
import { COMPANION_CLICK_THROUGH_URL_CLASS } from './utility:constants.js';
import { ExtendedError } from './utility:extended-error.js';

export type Options<Resolvers extends Playback.Resolvers<any>> = {
  api: Playback.Api;
  logger: Logger;
  resolvers: Resolvers;
};

export function createPlayer<
  Resolvers extends Playback.Resolvers<any>,
  Station extends Playback.Station,
>({ api, logger, resolvers }: Options<Resolvers>): Playback.Player<Station> {
  const ads = createWebStorage<Playback.Ads>({
    seed: {
      current: undefined,
      companionClickThroughs: [],
      enabled: true,
      env: null,
      errors: [],
      history: [],
      sessionid: uuid(),
      sessionstart: true,
      status: Playback.AdPlayerStatus.Idle,
      subscriptionType: 'free',
      targeting: {} as Playback.Targeting,
      type: 'unknown',
    },
    prefix: 'player:ads.',
    type: 'session',
  });

  const state = createWebStorage<Playback.PlayerState<Station>>({
    seed: {
      errors: [],
      history: [],
      index: 0,
      isScanning: false,
      metadata: null,
      muted: false,
      queue: [],
      repeat: Playback.Repeat.Yes,
      shuffled: false,
      skips: Number.MAX_SAFE_INTEGER,
      speed: Playback.Speed.Normal,
      station: {
        context: 0,
        type: Playback.StationType.Live,
        id: 1469,
      } as Station,
      status: Playback.Status.Idle,
      time: { duration: 0, position: 0 },
      volume: 50,
      pageName: 'home',
      lsid: '',
      podcastTritonTokenEnabled: false,
    },
    prefix: 'player:state.',
    type: 'local',
  });
  const throttleSetStateTime = throttle((value: Playback.Time) => {
    state.set('time', value);
  }, 5000);

  const timeState = createMemoryStorage<{ time: Playback.Time }>({
    time: {
      duration: 0,
      position: 0,
    },
  });
  timeState.set('time', state.get('time'));
  timeState.subscribe({
    set: (_, _key, value) => {
      throttleSetStateTime(value);
    },
  });

  state.serialize({
    ...state.deserialize(),
    errors: [],
    status: Playback.Status.Idle,
  });

  async function load(
    payload: Parameters<Playback.PlayerProperties<Station>['load']>[0],
  ) {
    const station = PlayerError.InvalidStation.validate(payload);

    const resolver = resolvers[station.type];

    const { station: currentStation } = state.deserialize();

    if (station.type === Playback.StationType.Scan) {
      resolver.internalState?.clear();
    } else {
      state.set('isScanning', false);
    }

    // Reset the ads state, generating a new `sessionid` and resetting `sessionstart` to true, as
    // well as setting `current` to `undefined`. `sessionstart` and `sessionid` aren't used for Live/Scan
    if (
      station.type !== Playback.StationType.Live &&
      station.type !== Playback.StationType.Scan
    ) {
      ads.serialize({
        ...ads.deserialize(),
        status: Playback.AdPlayerStatus.Idle,
        sessionstart: true,
        sessionid: uuid(),
        current: undefined,
        targeting: station.targeting,
      });
    } else {
      ads.serialize({
        ...ads.deserialize(),
        status: Playback.AdPlayerStatus.Idle,
        sessionstart: null,
        sessionid: null,
        current: undefined,
        targeting: station.targeting,
      });
    }

    const adsState = { ...ads.deserialize() };
    const current = { ...state.deserialize() };
    const { time } = { ...timeState.deserialize() };

    const result = await resolver.load(
      { api, state: current, ads: adsState, logger, time },
      payload,
    );

    if (result) {
      if (!result.time) {
        result.time = { duration: 0, position: 0 };
      }
      timeState.serialize({ time: result.time });
      let index = result.index;

      if (station.type === Playback.StationType.Scan) {
        // look for the "current" stationId in the result
        const stationIndex = result.queue.findIndex((item) => item.id === currentStation?.id);
        if (stationIndex > -1) {
          // If we found it, set the index to the next station, or zero if it would go past the
          // end of the queue
          index = stationIndex + 1
          if (index > result.queue.length - 1) {
            index = 0
          }
        }
      }

      state.serialize({
        ...current,
        ...result,
        index,
      });
    }

    player.setMetadata();

    return state.deserialize();
  }

  async function setScanning({ isScanning }: { isScanning: boolean }) {
    state.set('isScanning', isScanning);
    // If we're no longer scanning...
    if (!isScanning) {
      // Get the current queue item
      const { queue, index, station } = state.deserialize();
      const { id, meta } = queue[index];
      const { stationType } = meta;

      // If it is a live station...
      if (
        stationType &&
        stationType === Playback.StationType.Live &&
        station
      ) {
        const adsState = { ...ads.deserialize() };
        const current = { ...state.deserialize() };
        const { time } = { ...timeState.deserialize() };
        const stationToLoad: Playback.LiveStation = {
          context: station.context,
          targeting: station.targeting,
          id: Number(id),
          type: Playback.StationType.Live,
        };
        // Execute the live resolver `load` method
        const result = await resolvers[Playback.StationType.Live].load(
          {
            api,
            state: current,
            ads: adsState,
            time,
            logger
          },
          stationToLoad,
        );

        // and if we get a result
        if (result) {
          if (!result.time) {
            result.time = { duration: 0, position: 0 };
          }
          // Replace the current time state
          timeState.serialize({ time: result.time });
          // and player state
          state.serialize(result);
          // and then set the metadata
          player.setMetadata();
        }
      }
    }
  }

  const player = createEmitter<Playback.PlayerProperties<Station>>({
    async initialize() {
      state.set('status', Playback.Status.Idle);
      ads.set('status', Playback.AdPlayerStatus.Idle);
      ads.set('type', 'unknown');

      const time: Playback.Time = (function getStoredTime() {
        const { queue = [], index = 0 } = state.deserialize();

        return (
          queue[index]?.type === Playback.QueueItemType.Episode ?
            // if the current item is a podcast episode,
            {
              // set position to the starttime of the current item (stored in state)
              position: queue[index]?.starttime ?? 0,
              duration: queue[index]?.duration ?? 0,
            }
            // if the current item is a track
          : queue[index]?.type === Playback.QueueItemType.Track ?
            // set the position to 0, and the duration to the value stored in the item meta
            {
              position: 0,
              duration: queue[index]?.meta?.duration ?? 0,
            }
            // if the current item is a stream
          : queue[index]?.type === Playback.QueueItemType.Stream ?
            // set the position to 0 and the duration to Infinity (streams have no duration)
            { position: 0, duration: Number.POSITIVE_INFINITY }
            // else, set both to 0
          : { position: 0, duration: 0 }
        );
      })();

      state.serialize({
        ...state.deserialize(),
        errors: [],
        time,
        status: Playback.Status.Idle,
      });

      timeState.set('time', time);

      return state.deserialize();
    },

    async adComplete(type) {
      const station = PlayerError.InvalidStation.validate(state.get('station'));

      ads.set('companionClickThroughs', []);
      const clickThroughPixels = Array.from(
        document.querySelectorAll('.' + COMPANION_CLICK_THROUGH_URL_CLASS),
      );

      // Remove companionClickThroughs when the ad is complete
      for (const pixel of clickThroughPixels) {
        pixel.remove();
      }

      const payload = ads.get('current');

      if (!isNull(payload) && !isUndefined(payload)) {
        const history = ads.get('history');

        const ad: Playback.Ad = {
          format: payload.format,
          station,
          status: Playback.AdStatus.Complete,
          type: payload.type,
          tag: payload.tag,
          timestamp: Date.now(),
        };

        ads.set('history', [...history, ad]);

        ads.set('current', undefined);
      }

      if (
        payload?.type === Playback.AdType.Midroll ||
        type === Playback.AdType.Midroll
      ) {
        player.next(true);
      }

      player.setMetadata();
      return payload?.type ?? type ?? null;
    },

    async adEnd() {
      const { station } = state.deserialize();
      if (station) {
        const resolver = resolvers[station.type];
        await resolver.adEnd?.();
      }
      ads.set('status', Playback.AdPlayerStatus.Done);
    },

    async adStart(ad) {
      if (ad) {
        const isAudio =
          ad.creativetype.includes('audio') ||
          mime.lookup(ad.mediaFile?.file)?.includes('audio');
        const isVideo =
          !isAudio &&
          (ad.creativetype.includes('video') ||
            mime.lookup(ad.mediaFile?.file)?.includes('video') ||
            ad.mediaFile?.file.includes('video'));

        ads.set(
          'type',
          isAudio ? 'audio'
          : isVideo ? 'video'
          : 'unknown',
        );
        ads.set('status', Playback.AdPlayerStatus.Playing);

        const currentAd = ads.get('current');

        if (!isNullish(currentAd)) {
          currentAd.totalAds = ad.ima?.ad.data.adPodInfo.totalAds ?? 1;
          currentAd.adIndex = ad.ima?.ad.data.adPodInfo.adPosition ?? 1;

          const clickThroughUrl = ads.get('companionClickThroughs')[
            currentAd.adIndex - 1
          ];

          const companions = CompanionsSchema.safeParse(
            ad.ima?.ad.data.companions,
          );
          if (companions.success && isAudio) {
            if (Array.isArray(companions.data) && companions.data.length > 0) {
              currentAd.companions = companions.data.map(companion => ({
                ...companion,
                clickThroughUrl,
              }));
            } else {
              currentAd.companions = null;
            }
          } else if (!companions.success) {
            player.setError(
              PlayerError.new({
                code: PlayerErrorCode.AdsMetadata,
                message: 'Failed to parse companion ads!',
                data: {
                  ...companions.error,
                },
              }),
            );
            currentAd.companions = null;
          }
          ads.set('current', { ...currentAd });
        }

        const { name, meta } = state.get('station');
        state.set('metadata', {
          type: Playback.MetadataType.Ad,
          data: {
            ...meta,
            subtitle: `${name ?? 'Your content'} will play after the break`,
          },
        });
      } else {
        ads.set('status', Playback.AdPlayerStatus.Buffering);
      }
    },

    async adRequest() {
      ads.set('status', Playback.AdPlayerStatus.Buffering);
    },

    async fastForward(seconds) {
      const currentState = clone(state.deserialize());
      const { time: currentTime } = clone(timeState.deserialize());
      const currentAds = clone(ads.deserialize());

      const { queue, index } = currentState;
      const { duration, position: currentPosition } = currentTime;

      PlayerError.InvalidSeekType.validate(queue[index].type);
      PlayerError.InvalidSeekValue.validate(seconds);
      PlayerError.RestrictedDuringAdBreak.validate(
        currentAds.status !== Playback.AdPlayerStatus.Idle &&
          currentAds.status !== Playback.AdPlayerStatus.Done,
      );

      const position =
        currentPosition + seconds >= duration ?
          duration
        : currentPosition + seconds;

      player.seek(position);

      return position;
    },

    _get() {
      const station = PlayerError.InvalidStation.validate(state.get('station'));

      const resolver = resolvers[station.type];
      return resolver.internalState;
    },

    getAds() {
      return ads;
    },

    getState() {
      return state;
    },

    getTime() {
      return timeState;
    },

    load,

    async loadAdXml({ adPayload, xmlDoc, companionClickThroughs }) {
      ads.set('companionClickThroughs', companionClickThroughs);
      return { adPayload, xmlDoc, companionClickThroughs };
    },

    async midroll() {
      const station = PlayerError.InvalidStation.validate(state.get('station'));

      const currentPlayerState = { ...state.deserialize() };
      const currentTimeState = { ...timeState.deserialize() };
      const currentAdsState = { ...ads.deserialize() };

      if (!currentAdsState.enabled) {
        player.next(true);
        return null;
      }

      const resolver = resolvers[station.type];

      const payload = await resolver.midroll?.({
        ads: currentAdsState,
        api,
        logger,
        state: currentPlayerState,
        time: currentTimeState.time,
      });

      if (isNull(payload) || isUndefined(payload)) {
        ads.set('status', Playback.AdPlayerStatus.Done);

        if (station.type !== Playback.StationType.Live) {
          player.next(true);
        }

        return null;
      } else {
        const adRequestUrl = new URL(payload.tag);

        const { sessionstart, sessionid } = ads.deserialize();

        adRequestUrl.searchParams.append('correlator', String(Date.now()));
        adRequestUrl.searchParams.append('type', Playback.AdType.Midroll);

        if (
          station.type !== Playback.StationType.Live &&
          !isNull(sessionid) &&
          !isNull(sessionstart)
        ) {
          // Set the current `sessionstart` and `sessionid` values
          adRequestUrl.searchParams.set(
            'sessionstart',
            sessionstart.toString(),
          );
          adRequestUrl.searchParams.set('sessionid', sessionid);

          // and then set `sessionstart` to false in the ads state, for subsequent requests
          // `sessionstart` gets reset to `true` in the `player.load` method, so new stations will
          // have `sessionstart: true` on their first post-roll ad request
          ads.set('sessionstart', false);

          // Google IMA plugin throws a fit when you ask it to fetch an ad payload from Triton
          // because of CORS issues, so now we just fetch the VAST document directly and load it
          // into JWPlayer through the `loadAdXml` method.
          try {
            // fetch the VAST response
            const adRequest = await fetch(adRequestUrl, { redirect: 'follow' });

            // If successful,
            if (adRequest.status === 200) {
              const adXmlString = await adRequest.text();

              // Parse it into an XML Document
              const adXmlDocument = new DOMParser().parseFromString(
                adXmlString,
                'text/xml',
              );

              // and query for the root VAST node
              const rootNode = adXmlDocument.querySelector('VAST');

              // if it exists and has children (meaning there is an ad to play)
              if (rootNode && rootNode.childNodes.length > 0) {
                const companionClickThroughs = [];
                const adNodes = Array.from(rootNode.querySelectorAll('Ad'));
                for (const adNode of adNodes) {
                  const companionClickThrough = adNode.querySelector(
                    'CompanionClickThrough',
                  );
                  companionClickThroughs.push(
                    companionClickThrough?.textContent ?? null,
                  );
                }
                // Send the XML document to `player.loadXAdXml`. The corresponding method in JW
                // Player subscription will parse it back to a string. With that caveat, that it
                // only parses the `documentElement` into string, which in effect removes the top
                // line `<xml .../>` declaration, which seems to make JW Player choke (sometimes)
                player.loadAdXml({
                  adPayload: payload,
                  xmlDoc: adXmlDocument,
                  companionClickThroughs,
                });
                ads.set('current', {
                  ...payload,
                  tag: adRequestUrl.toString(),
                });

                // If there's no ad to play, just go to the next item in the queue
              } else {
                player.next(true);
              }

              // If the request to Triton was not 200, just go to the next item in the queue
            } else {
              player.next(true);
            }
          } catch {
            // If there was an error thrown in any of that, set a Midroll error on the player.
            // `player.setError` will call `player.next` if the code is Midroll, so no need to do
            // that here
            player.setError(
              PlayerError.new({
                code: PlayerErrorCode.Midroll,
                message: PlayerErrorMessages[PlayerErrorCode.Midroll],
              }),
            );
          }

          // This *shouldn't* ever execute, but if for some reason `sessionid` or `sessionstart`
          // are null and we aren't playing a Live station, go ahead and go to the next queue item
        } else if (station.type !== Playback.StationType.Live) {
          player.next(true);
        }

        return payload;
      }
    },

    async next(internal = false) {
      const station = PlayerError.InvalidStation.validate(state.get('station'));
      const { time } = timeState.deserialize();

      if (!internal) {
        PlayerError.SkipLimit.validate(state.get('skips'));
      }

      const resolver = resolvers[station.type];

      const next = PlayerError.UnsupportedMethod.validate(resolver.next);

      const payload = await next(
        {
          api,
          state: state.deserialize(),
          ads: ads.deserialize(),
          logger,
          time,
        },
        internal,
      );

      if (isNull(payload)) {
        state.set('index', 0);

        if (state.get('repeat') === Playback.Repeat.No) {
          player.stop();
        }

        return payload;
      }

      
      if (payload.time) {
        timeState.set('time', payload.time);
      }
      return Promise.resolve(!payload.isScanning && station.type === Playback.StationType.Scan)
        .then(setScanningFalse => {
          if (setScanningFalse) {
            player.setScanning({ isScanning: false });
          }
          return setScanningFalse;
        }).then((scanningWasSet) => {
          if (!scanningWasSet) {
            state.serialize(payload);
            player.setMetadata();
          }

          player.play();

          return payload;
        });
    },

    async pause() {
      const station = PlayerError.InvalidStation.validate(state.get('station'));
      const { time } = timeState.deserialize();

      const resolver = resolvers[station.type];

      await resolver.pause?.({
        api,
        ads: ads.deserialize(),
        logger,
        state: state.deserialize(),
        time,
      });

      if (
        ads.get('status') !== Playback.AdPlayerStatus.Done &&
        ads.get('status') !== Playback.AdPlayerStatus.Idle
      ) {
        player.pauseAd(ads.get('status') === Playback.AdPlayerStatus.Playing);
        return ads.get('status') === Playback.AdPlayerStatus.Playing ?
            Playback.Status.Paused
          : Playback.Status.Playing;
      }

      state.set('status', Playback.Status.Paused);

      return Playback.Status.Paused;
    },

    async pauseAd(pause) {
      state.set(
        'status',
        pause ? Playback.Status.Paused : Playback.Status.Playing,
      );
      ads.set(
        'status',
        pause ?
          Playback.AdPlayerStatus.Paused
        : Playback.AdPlayerStatus.Playing,
      );

      return pause;
    },

    async play() {
      const station = state.get('station');

      PlayerError.InvalidStation.validate(station);

      if (
        ads.get('status') !== Playback.AdPlayerStatus.Done &&
        ads.get('status') !== Playback.AdPlayerStatus.Idle
      ) {
        player.pauseAd(ads.get('status') === Playback.AdPlayerStatus.Playing);
        return ads.get('status') === Playback.AdPlayerStatus.Playing ?
            Playback.Status.Paused
          : Playback.Status.Playing;
      }

      const queue = state.get('queue');
      const index = state.get('index');
      const item = queue[index];

      const history = state
        .get('history')
        .filter(history => history.item?.type !== Playback.QueueItemType.Event);
      const lastItem = history.at(-1)?.item;

      const sameItem =
        isUndefined(lastItem) ? false : (
          isDeepEqual(
            omit(item, ['starttime', 'url']),
            omit(lastItem, ['starttime', 'url']),
          )
        );

      if (!sameItem) {
        state.set(
          'history',
          [...history, { item, station, timestamp: Date.now() }].slice(-50),
        );
        timeState.set('time', { duration: 1, position: 0 });
      }

      const pauseOrStop =
        item.type === Playback.QueueItemType.Stream ?
          Playback.Status.Idle
        : Playback.Status.Paused;

      // If `item.meta` includes a `restart` key, that takes precedence over anything else
      // When `restart` is true, this makes sure the jwplayer seeks back to the beginning for playing the episode
      const status =
        sameItem ?
          {
            [Playback.Status.Buffering]: pauseOrStop,
            [Playback.Status.Idle]:
              item.meta.restart ?
                Playback.Status.Restart
              : Playback.Status.Playing,
            [Playback.Status.Paused]:
              item.meta.restart ?
                Playback.Status.Restart
              : Playback.Status.Playing,
            [Playback.Status.Playing]: pauseOrStop,
            [Playback.Status.Restart]: Playback.Status.Playing,
          }[state.get('status')]
        : Playback.Status.Playing;

      state.set('status', status);

      // If we need to restart, `status` is now set correctly and then we delete `restart` from the `item`
      delete item.meta.restart;
      queue[index] = item;
      state.set('queue', queue);

      const resolver = resolvers[station.type];

      if (resolver && resolver.play && status === Playback.Status.Playing) {
        return await resolver.play({
          api,
          state: state.deserialize(),
          ads: ads.deserialize(),
          logger,
          time: timeState.deserialize().time,
        });
      }

      return status;
    },

    async playAd(tag) {
      PlayerError.RestrictedDuringAdBreak.validate(
        ads.get('status') !== Playback.AdPlayerStatus.Idle &&
          ads.get('status') !== Playback.AdPlayerStatus.Done,
      );

      state.set('status', Playback.Status.Playing);
      ads.set('status', Playback.AdPlayerStatus.Buffering);

      return tag;
    },

    async preroll() {
      const station = PlayerError.InvalidStation.validate(state.get('station'));

      const currentPlayerState = { ...state.deserialize() };
      const currentTimeState = { ...timeState.deserialize() };
      const currentAdsState = { ...ads.deserialize() };

      if (
        !currentAdsState.enabled ||
        !Playback.PreRollStationSchema.safeParse(station.type).success
      ) {
        ads.set('status', Playback.AdPlayerStatus.Done);
        return null;
      }

      const resolver = resolvers[station.type];
      const payload = await resolver.preroll?.({
        api,
        state: currentPlayerState,
        ads: currentAdsState,
        logger,
        time: currentTimeState.time,
      });

      if (isNull(payload) || isUndefined(payload)) {
        ads.set('status', Playback.AdPlayerStatus.Done);
        return null;
      }

      ads.set('current', payload);

      const ad = new URL(payload.tag);

      ad.searchParams.set('correlator', String(Date.now()));
      ad.searchParams.set('type', Playback.AdType.Preroll);

      player.playAd(ad.toString());

      return payload;
    },

    async previous(internal = true) {
      const currentState = clone(state.deserialize());
      const { station: currentStation } = currentState;
      const station = PlayerError.InvalidStation.validate(currentStation);

      if (!internal) {
        PlayerError.SkipLimit.validate(state.get('skips'));
      }

      const resolver = resolvers[station.type];

      const previous = PlayerError.UnsupportedMethod.validate(
        resolver.previous,
      );

      const newState = await previous({
        api,
        state: state.deserialize(),
        ads: ads.deserialize(),
        logger,
        time: timeState.deserialize().time,
      });

      if (isNull(newState)) {
        state.set('index', 0);

        if (state.get('repeat') === Playback.Repeat.No) {
          player.stop();
        }

        return newState;
      }

      if (newState.time) {
        timeState.set('time', newState.time);
      }

      state.serialize({
        ...currentState,
        ...newState,
      });

      player.setMetadata();
      player.play();

      return {
        ...currentState,
        ...newState,
      };
    },

    async rewind(seconds) {
      const currentState = clone(state.deserialize());
      const { time: currentTime } = clone(timeState.deserialize());
      const currentAds = clone(ads.deserialize());

      const { queue, index } = currentState;
      const { position: currentPosition } = currentTime;

      PlayerError.InvalidSeekType.validate(queue[index].type);
      PlayerError.InvalidSeekValue.validate(seconds);
      PlayerError.RestrictedDuringAdBreak.validate(
        currentAds.status !== Playback.AdPlayerStatus.Idle &&
          currentAds.status !== Playback.AdPlayerStatus.Done,
      );

      const position =
        currentPosition - seconds <= 0 ? 0 : currentPosition - seconds;

      player.seek(position);

      return position;
    },

    async seek(newPosition) {
      const currentState = clone(state.deserialize());
      const { time: currentTime } = clone(timeState.deserialize());
      const currentAds = clone(ads.deserialize());

      const { queue, index, station: currentStation } = currentState;
      const station = PlayerError.InvalidStation.validate(currentStation);
      const { duration } = currentTime;

      PlayerError.InvalidSeekType.validate(queue[index].type);
      PlayerError.InvalidSeekValue.validate(newPosition);
      PlayerError.RestrictedDuringAdBreak.validate(
        currentAds.status !== Playback.AdPlayerStatus.Idle &&
          currentAds.status !== Playback.AdPlayerStatus.Done,
      );

      const seek = resolvers[station.type].seek;

      const position =
        newPosition >= duration ? duration
        : newPosition <= 0 ? 0
        : newPosition;

      const newTime = {
        duration,
        position,
      };

      seek?.(
        {
          api,
          ads: currentAds,
          logger,
          state: currentState,
          time: currentTime,
        },
        position,
      );
      timeState.set('time', newTime);
      state.serialize({
        ...currentState,
        time: newTime,
      });

      return newTime.position;
    },

    async setError(error) {
      const station = state.get('station');

      let instance = error;

      if (isNull(station)) {
        instance = PlayerError.new({
          code: PlayerError.InvalidStation.code,
          data: station,
          message: PlayerErrorMessages[PlayerErrorCode.InvalidStation],
        });
      } else if (error instanceof ExtendedError) {
        switch (error.code) {
          case PlayerError.Preroll.code: {
            ads.set('errors', [...ads.get('errors'), error]);

            const payload = ads.get('current');

            if (isNull(payload) || isUndefined(payload)) {
              return error;
            }

            ads.set('history', [
              ...ads.get('history'),
              {
                format: payload.format,
                station,
                status: Playback.AdStatus.Error,
                type: Playback.AdType.Preroll,
                tag: payload.tag,
                timestamp: Date.now(),
              },
            ]);

            ads.set('current', undefined);

            return error;
          }
          case PlayerError.Midroll.code: {
            ads.set('errors', [...ads.get('errors'), error]);

            const payload = ads.get('current');

            if (isNull(payload) || isUndefined(payload)) {
              return error;
            }

            ads.set('history', [
              ...ads.get('history'),
              {
                format: payload.format,
                station,
                status: Playback.AdStatus.Error,
                type: Playback.AdType.Preroll,
                tag: payload.tag,
                timestamp: Date.now(),
              },
            ]);

            ads.set('current', undefined);

            player.next(true);

            return error;
          }
          default: {
            instance = error;

            break;
          }
        }
      } else {
        instance = PlayerError.new({
          code: PlayerError.Generic.code,
          data: error,
          message: PlayerError.Generic.code,
        });
      }

      const resolver = resolvers[station.type];

      await resolver.setError?.(instance);

      state.set('errors', [...state.get('errors'), { ...instance }]);

      return instance;
    },

    async setMetadata(metadata) {
      const station = PlayerError.InvalidStation.validate(state.get('station'));

      if (!isUndefined(metadata) && !isNull(metadata)) {
        state.set('metadata', metadata);
        return metadata;
      }

      const resolver = resolvers[station.type];

      const setMetadata = PlayerError.UnsupportedMethod.validate(
        resolver.setMetadata,
      );

      const nextMetadata = PlayerError.InvalidMetadata.validate(
        await setMetadata(
          {
            ads: ads.deserialize(),
            api,
            logger,
            state: state.deserialize(),
            time: timeState.deserialize().time,
          },
          null,
        ),
      );

      state.set('metadata', nextMetadata);

      return nextMetadata;
    },

    async setMute(newMuted) {
      const muted = newMuted ?? !state.get('muted');

      state.set('muted', muted);

      return muted;
    },

    async setRepeat(repeat) {
      state.set('repeat', repeat);

      return repeat;
    },

    setScanning,

    async setShuffle(newShuffled) {
      const currentState = clone(state.deserialize());
      const { station: currentStation } = currentState;
      const station = PlayerError.InvalidStation.validate(currentStation);

      const resolver = resolvers[station.type];

      const shuffle = PlayerError.UnsupportedMethod.validate(
        resolver.setShuffle,
      );

      const shuffled = newShuffled ?? !state.get('shuffled');

      const newState = await shuffle(
        {
          ads: ads.deserialize(),
          api,
          logger,
          state: state.deserialize(),
          time: timeState.deserialize().time,
        },
        shuffled,
      );

      state.serialize({
        ...currentState,
        ...newState,
        shuffled,
      });

      return {
        ...currentState,
        ...newState,
        shuffled,
      };
    },

    async setSpeed(speed) {
      const item = state.get('queue')[state.get('index')];

      PlayerError.InvalidSpeed.validate(speed);
      PlayerError.InvalidSpeedType.validate(item.type);
      PlayerError.InvalidStation.validate(state.get('station'));
      PlayerError.RestrictedDuringAdBreak.validate(
        ads.get('status') !== Playback.AdPlayerStatus.Idle &&
          ads.get('status') !== Playback.AdPlayerStatus.Done,
      );

      state.set('speed', speed);

      return speed;
    },

    async setStatus(status) {
      PlayerError.InvalidStation.validate(state.get('station'));

      state.set('status', status);

      return status;
    },

    async setTime({ duration, position }) {
      const currentState = { ...state.deserialize() };
      const { station: currentStation, queue, index } = currentState;

      const station = PlayerError.InvalidStation.validate(currentStation);

      // If we're playing a stream, bail early. There is an issue with (I think) HLS.js where it
      // will randomly supply a duration/position that are wildly wrong, example -
      // `{ duration: -120.3894, position: -2.1449 }`, which in turn causes the validate method
      // below to throw an error. Since this is a stream, and we don't show time values or even
      // the scrubber bar, we can just return the state as-is. [DEM 2024/04/24]
      //
      // If the station type is Scan, we need to check to see if the scan duration has elapsed
      if (
        queue?.[index]?.type === Playback.QueueItemType.Stream &&
        station.type !== Playback.StationType.Scan
      ) {
        return {
          ...currentState,
        };
      }

      const resolver = resolvers[station.type];

      if (station.type !== Playback.StationType.Scan) {
        const time = PlayerError.InvalidTime.validate({
          duration,
          position,
        });

        const newState = await resolver.setTime?.(
          {
            ads: ads.deserialize(),
            api,
            logger,
            state: currentState,
            time,
          },
          time,
        );

        state.serialize({
          ...currentState,
          ...newState,
        });
        timeState.set('time', time);

        return {
          ...currentState,
          ...newState,
          time,
        };
      } else {
        try {
          const time = { duration: 0, position: 0 };

          const newState = await resolver.setTime?.(
            {
              ads: ads.deserialize(),
              api,
              logger,
              state: currentState,
              time,
            },
            time,
          );

          state.serialize({
            ...currentState,
            ...newState,
          });
          if (newState?.time) {
            timeState.set('time', newState?.time);
          }

          return {
            ...currentState,
            ...newState,
          };
        } catch (error: unknown) {
          // If Scan station throws an object with `scanNext`, then we should go to the next
          // item in the queue by calling `player.next`
          if (error && typeof error === 'object' && 'scanNext' in error) {
            player.next(true);
          }
          return {
            ...currentState,
          };
        }
      }
    },

    async setVolume(volume) {
      PlayerError.InvalidVolume.validate(volume);

      state.set('muted', false);
      state.set('volume', volume);

      return volume;
    },

    async stop() {
      if (ads.get('status') === Playback.AdPlayerStatus.Playing) {
        player.pauseAd(true);

        return Playback.Status.Paused;
      }

      state.set('status', Playback.Status.Idle);
      state.set('isScanning', false);
      ads.set('status', Playback.AdPlayerStatus.Idle);
      timeState.set('time', { ...timeState.get('time'), position: 0 });

      const { station: currentStation } = state.deserialize();

      const station = PlayerError.InvalidStation.validate(currentStation);

      const resolver = resolvers[station.type];

      await resolver.stop?.();

      return Playback.Status.Idle;
    },
  });

  player.subscribe({
    catch(_, error, ...args) {
      player.setError(
        error instanceof ExtendedError ? error : (
          PlayerError.new({
            code: PlayerError.Code.Generic,
            message: error.message,
            data: { error, args },
          })
        ),
      );
    },
  });

  return player;
}
